/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { AxiosRequestConfig } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { IServer } from "@/models/server/IServer";
export interface User {
  email: string;
  name: string;
  role_id: number;
  user_id: number;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

export interface AuthToken {
  authToken: string;
  refreshToken: string;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [Error];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken()?.authToken;
  serverDetail = {} as IServer;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  get currentServer() {
    return this.serverDetail;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors.push(error);
  }

  @Mutation
  [Mutations.SET_AUTH](token: AuthToken) {
    this.isAuthenticated = true;
    this.errors = [];
    JwtService.saveToken(token);
  }

  @Mutation
  [Mutations.SET_USER](userData) {
    this.user = userData;
  }

  // @Mutation
  // [Mutations.SET_PASSWORD](password) {
  //   this.user.password = password;
  // }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Mutation
  [Mutations.SET_SERVER_DETAIL](serverDetailData) {
    this.serverDetail = serverDetailData;
  }

  @Action
  [Actions.LOGIN](credentials) {
    const params = {
      ...credentials,
    };
    return ApiService.post("login", params)
      .then(({ data }) => {
        const token2 = data.data.token;
        const dataUser = JSON.parse(atob(token2.split(".")[1]));
        const token = {
          authToken: data.data.token,
          refreshToken: data.data.refresh,
        } as AuthToken;
        this.context.commit(Mutations.SET_AUTH, token);
        this.context.commit(Mutations.SET_USER, dataUser.user);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, new Error(response.data.data));
        throw new Error(response.data.data);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, new Error(response.data.data));
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    const params = {
      params: {
        ...payload,
      },
    };
    return ApiService.query("forgot_password", params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, new Error(response.data.data));
      });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()?.authToken) {
      ApiService.setHeader();
      ApiService.get("database/v1/")
        .then(() => {
          const currentAuthToken: string = JwtService.getToken()
            ?.authToken as string;
          const dataUser = JSON.parse(atob(currentAuthToken?.split(".")[1]));
          const unixNow = Math.round(+new Date() / 1000);

          if (dataUser.exp < unixNow) this.context.commit(Mutations.PURGE_AUTH);
          else {
            this.context.commit(Mutations.SET_AUTH, JwtService.getToken());
            this.context.commit(Mutations.SET_USER, dataUser.user);
          }
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            new Error(response.data.data)
          );
          this.context.dispatch(Actions.REFRESH_TOKEN);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.REFRESH_TOKEN]() {
    if (JwtService.getToken()?.refreshToken) {
      ApiService.post("refresh-token", {
        token: JwtService.getToken()?.refreshToken,
      } as AxiosRequestConfig)
        .then(({ data }) => {
          const accessToken = {
            authToken: data.data.token,
            refreshToken: data.data.refresh,
          } as AuthToken;

          this.context.commit(Mutations.SET_AUTH, accessToken);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            new Error(response.data.data)
          );
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  // @Action
  // [Actions.UPDATE_USER](payload) {
  //   ApiService.setHeader();
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.post("update_user", payload)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_USER, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //         reject();
  //       });
  //   });
  // }

  @Action
  [Actions.GET_SERVER_DETAIL](serverId: number) {
    ApiService.vueInstance.axios
      .get(`database/v1/server/${serverId}/detail`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SERVER_DETAIL, data.data);
      });
  }
}
