const AUTH_TOKEN_KEY = "auth_token" as string;
const REFRESH_TOKEN_KEY = "refresh_token" as string;

export interface AuthToken {
  authToken: string;
  refreshToken: string;
}

/**
 * @description get token form localStorage
 */
export const getToken = (): AuthToken | null => {
  const result = {
    authToken: window.localStorage.getItem(AUTH_TOKEN_KEY),
    refreshToken: window.localStorage.getItem(REFRESH_TOKEN_KEY),
  } as AuthToken;

  return result;
};

/**
 * @description save token into localStorage
 * @param token: AuthToken
 */
export const saveToken = (token: AuthToken): void => {
  window.localStorage.setItem(AUTH_TOKEN_KEY, token.authToken);
  window.localStorage.setItem(REFRESH_TOKEN_KEY, token.refreshToken);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(AUTH_TOKEN_KEY);
  window.localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };
